import * as React from 'react';
import styles from './progressBarAnimation.scss';
import { IProgressBarAnimationProps, IProgressBarAnimationState } from './progressBarAnimation.d';
import * as helper from '../../Common/html-helper';

export default class ProgressBarAnimation extends React.Component<IProgressBarAnimationProps, IProgressBarAnimationState> {
    constructor(props: IProgressBarAnimationProps) {
        super(props);

        this.state = {
            width: 0,
        }

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('scroll', this.handleScroll);

        if(this.props.totalResultCount > 0 && this.props.totalResultCount < 5) {
            this.setState({
                width: 100
            });
        }

        if(window && document){
            setTimeout(()=>{
                const hasPreviousPage = this.props.pageEndOffset - this.props.salesDesignationViews.length > 0;
                const isAtTop = this.getDocScrollRatio() < .1;
                if (hasPreviousPage && isAtTop) {
                    //we're at the top and want to trigger previous page ajax loading without scrolling
                    this.props.infiniteScroll();
                }
            }, 500);
        }
    }

    getDocScrollRatio(){
        const domElement = document.documentElement;
        const ratio = domElement.scrollTop / (domElement.scrollHeight - domElement.clientHeight);
        return ratio;
    }

    componentWillUnmount(): void {
        if (typeof document !== 'undefined') {
            document.removeEventListener('scroll', this.handleScroll);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProgressBarAnimationProps>) {
        if (prevProps.scrollPosition !== this.props.scrollPosition) {
            this.setState({ width: this.props.scrollPosition });
        }
    }

    private handleScroll(): void {
        const setter = newScrollPosition => this.setState({
            width: newScrollPosition
        });
        helper.onScroll(this.props.pageEndOffset, this.props.salesDesignationViews, setter, this.props.infiniteScroll);
    }

    public render() {
        const barWidth = this.state.width > 100 ? 100 : this.state.width;

        return (
            <div className={styles.progress_bar_frame}>
                <div className={styles.progress_bar}
                    style={{ width: barWidth.toString() + '%' }}>
                </div>
            </div>
        );
    }
}
