import React, { Component } from 'react';
import SidePanel from './sidePanel/sidePanel';
import { IMainSidePanelProps } from './mainSidePanel.d';
import { ScrollLock } from '../../../Helper/scrollLock';
import { ContentType } from './sidePanel/contentType';
import { IEsAppService } from './esAppService';
import classnames from 'classnames';
import styles from './mainSidePanel.scss';
import { publish, subscribe, unsubscribe } from '../../Common/customEventHelper';

export default class MainSidePanel extends Component<IMainSidePanelProps,
    { showSidebar: boolean, currentTab: ContentType }> {
    private scrollLock: ScrollLock;
    private esAppService: IEsAppService = new IEsAppService();

    constructor(props) {
        super(props);
        this.state = { showSidebar: false, currentTab: ContentType.FilterContent };
        this.scrollLock = ScrollLock.instance;
        this.onCloseRequestedBySubComponent = this.onCloseRequestedBySubComponent.bind(this);
        this.onResetRequestedBySubComponent = this.onResetRequestedBySubComponent.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.bindEvent();
    }

    public render() {
        return (
            <div data-testid={'side-panel-app'} className={styles.side_panel_app}>
                <div data-testid={'sp-content'} className={classnames(styles.content,
                    { [styles.msp_hidden]: !this.state.showSidebar })}>
                    <SidePanel onClose={this.onCloseRequestedBySubComponent} onReset={this.onResetRequestedBySubComponent}
                        filterContentViewModel={this.props.filterContentViewModel}
                        currentTab={this.state.currentTab} onTabChange={this.handleContentChange}
                        isShow={this.state.showSidebar} shouldShowProductFinder={this.props.shouldShowProductFinder}
                        productFinderServicesUpAndRunning={this.props.productFinderServicesUpAndRunning}
                        globalState={this.props.globalState}
                    />
                </div>
            </div>
        );
    }

    public componentDidMount() {
        if (typeof window !== 'undefined' && window.shell) {
            if (new URL(window.location.href).searchParams.get('trouserfinder') === 'open') {
                this.updatePageUrl('trouserfinder');
                setTimeout(() => { this.open(ContentType.TrouserFinderContent); }, 300); // give some time for PF to react
            }

            if (new URL(window.location.href).searchParams.get('jacketfinder') === 'open') {
                this.updatePageUrl('jacketfinder');
                setTimeout(() => { this.open(ContentType.JacketFinderContent); }, 300); // give some time for PF to react
            }

            if (new URL(window.location.href).searchParams.get('shoefinder') === 'open') {
                this.updatePageUrl('shoefinder');
                setTimeout(() => { this.open(ContentType.ShoeFinderContent); }, 300); // give some time for PF to react
            }
        }
    }

    public componentWillUnmount() {
        unsubscribe('ESPP.MainSidePanel.ShouldOpen', (payload: CustomEvent) => {
            this.open(payload.detail);
        });

        unsubscribe('ESPP.MainSidePanel.ShouldClose', () => {
            this.close();
        });
    }

    public handleContentChange(tab: ContentType) {
        let payload: string = null;

        switch (this.state.currentTab) {
            case ContentType.FilterContent: {
                payload = ContentType.FilterContent;
                break;
            }
            case ContentType.TrouserFinderContent: {
                payload = ContentType.TrouserFinderContent;
                break;
            }
            case ContentType.JacketFinderContent: {
                payload = ContentType.JacketFinderContent;
                break;
            }
            case ContentType.ShoeFinderContent: {
                payload = ContentType.ShoeFinderContent;
                break;
            }
        }

        publish('ESPP.MainSidePanel.TabSwitchOff', payload);

        this.setState({ currentTab: tab }, this.finishOpening.bind(this, tab));
    }

    public open(payload: ContentType) {
        switch (payload) {
            case ContentType.TrouserFinderContent: {
                this.setState({ showSidebar: true, currentTab: ContentType.TrouserFinderContent },
                    this.finishOpening.bind(this, payload));
                break;
            }
            case ContentType.JacketFinderContent: {
                this.setState({ showSidebar: true, currentTab: ContentType.JacketFinderContent },
                    this.finishOpening.bind(this, payload));
                break;
            }
            case ContentType.ShoeFinderContent: {
                this.setState({ showSidebar: true, currentTab: ContentType.ShoeFinderContent },
                    this.finishOpening.bind(this, payload));
                break;
            }
            case ContentType.SortContent: {
                this.setState({ showSidebar: true, currentTab: ContentType.SortContent },
                    this.finishOpening.bind(this, payload));
                break;
            }
            default: {
                this.setState({ showSidebar: true, currentTab: ContentType.FilterContent },
                    this.finishOpening.bind(this, payload));
                break;
            }
        }
    }

    private onCloseRequestedBySubComponent() {
        if (document.getElementsByClassName('fas_tooltip_overlay').length > 0)
            document.getElementsByClassName('fas_tooltip_overlay')[0].classList.add('hide');

        this.close();
    }

    private onResetRequestedBySubComponent() {
        this.reset();
    }

    private bindEvent() {
        if (typeof window !== 'undefined' && typeof window.shell !== 'undefined') {
            window.esApp = this.esAppService;
        }

        subscribe('ESPP.MainSidePanel.ShouldOpen', (payload: CustomEvent) => {
            this.open(payload.detail);
        });
        
        subscribe('ESPP.MainSidePanel.ShouldClose', () => {
            this.close();
        });
    }

    private close() {
        if (typeof window !== 'undefined' && typeof window.shell !== 'undefined')
            window.shell.publishTo('ESPP.ProductFinder.LogCancelStep', this.state.currentTab);

        const sidebar = document.getElementsByClassName(styles.side_panel_app)[0];
        sidebar.classList.add(styles.slideout);
        this.esAppService.navigationWheel.show();
        this.scrollLock.unlock();
        setTimeout(() => {
            sidebar.classList.remove(styles.slideout);
            this.setState({ showSidebar: false }, () => {
                publish('ESPP.MainSidePanel.Closed', null);
            });
        }, 300);
    }

    private reset() {
        if (typeof window !== 'undefined' && typeof window.shell !== 'undefined')
            window.shell.publishTo('ProductFinder.Reset', this.props.filterContentViewModel.productFinders?.[0].key);
    }

    private finishOpening(payload: ContentType) {
        // ping finder to prepare for render:
        window.shell.publishTo('ProductFinder.Open', payload);

        if (typeof window !== 'undefined' && typeof window.shell !== 'undefined') {
            publish('ESPP.MainSidePanel.Opened', null);

            this.esAppService.navigationWheel.hide();
            if (!this.scrollLock.getLockState)
                this.scrollLock.lock();
        }
    }

    private updatePageUrl(scopeUrlParam: string): void {
        // This method removes trouserFinder paramter from url without page reload
        // It happens when customer comes to trousers category, 
        // and side bar opens automatically without any additional actions
        // Such a link is placed in a mobile main menu. Or also can be placed in any newsLetter

        // https://www-preview-dev.engelbert-strauss.de/en/trousers/?trouserfinder=open
        let currentUrl: string = window.location.href;

        // if tf is the only one parameter:
        currentUrl = currentUrl.replace('?' + scopeUrlParam + '=open', '');

        // if tf is combined with other parameters:
        currentUrl = currentUrl.replace('&' + scopeUrlParam + '=open', '');

        window.history.replaceState(null, '', currentUrl);
    }
}
