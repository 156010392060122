import React, { Component, RefObject } from 'react';
import classNames from 'classnames';
import styles from './article-tile.scss';
import TileDetails from './details/tileDetails';
import TileVariants from './variants/tileVariants';
import SlideShow from './slideShow/slideShow';
import ButtonColumn from './buttonColumn/buttonColumn';
import { ISalesArticleVariantsLookup, IArticleTileProps, ISdvColor, ISize } from './article-tile.d';
import AvailableSizes from './availableSizes/availableSizes';
import defaultStyles from '../../../Common/defaults.scss';
import * as helper from '../../../Common/html-helper';
import EyeCatcher from './eyeCatcher/eyeCatcher';
import {PageViewLogDataId, SearchTelemetryAjaxCaller, SearchTelemetryData } 
    from '../../searchresultpage/SearchTelemetryAjaxCaller';
import AvailableSizesBtn from './availableSizes/availableSizesBtn';
import { isTablet } from 'react-device-detect';
import { ViewType } from '../../../Common/enums';

export default class ArticleTile extends Component<IArticleTileProps, {
    isHovering: boolean,
    selectedColorVariant: ISdvColor,
    pdpLink: string,
    showAvailableSizesMenu: boolean,
    sizes: ISize[],
    salesArticleVariantsLookup: ISalesArticleVariantsLookup[],
    sizesLoaded: boolean,
    isTouchAction: boolean,
    itWasTouchMove: boolean,
    isDesktop: boolean,
    availableSizesEnabled: boolean,
    mounted: boolean
}> {
    private breakpoint_tablet_max_width_1199 = parseInt(defaultStyles.breakpoint_tablet_max_width_1199);
    private hoverStartupTimer: ReturnType<typeof setTimeout>;
    private elementRef: RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.state = {
            isHovering: false,
            selectedColorVariant: this.getDefaultColorVariant(),
            pdpLink: this.props.article.pdpLinkDefaultColor,
            showAvailableSizesMenu: false,
            sizes: [],
            salesArticleVariantsLookup: [],
            sizesLoaded: false,
            isTouchAction: false,
            itWasTouchMove: false,
            isDesktop: !this.props.isMobile,
            availableSizesEnabled: false,
            mounted: false,
        };

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.changeColorVariant = this.changeColorVariant.bind(this);
        this.toggleAvailableSizes = this.toggleAvailableSizes.bind(this);
        this.resetTouchEventState = this.resetTouchEventState.bind(this);
        this.handleTileClick = this.handleTileClick.bind(this);
        
        const isTeaserView = this.props.viewType === ViewType.TeaserSuggestion;
        const tileId = 'ats-' 
            + this.props.article.masterArticleNo.toString()
            + (isTeaserView ? `_${this.props.gridNumber}` : '');
        this.props.article.tileId = tileId;
        // the parent page component will need to have a ref to the DOM for each article
        // to do scroll distance calculations
        this.elementRef = React.createRef<HTMLDivElement>();
        this.props.article.elementRef = this.elementRef;
    }

    componentDidMount(): void {
        // for tablet devices:
        this.setState({
            isDesktop: window.innerWidth > this.breakpoint_tablet_max_width_1199,
            mounted: true
        }, () => {
             // check avs for mobile devices
            if(this.isNonDesktopDevice) {
                this.checkAvailableSizesViaBackend();
            }
        });
    }

    componentDidUpdate(): void {
        // react will reuse a component (based on key in parent) when we get new articles after filtering
        // so articles that were present before filtering already and stay after filtering will
        // receive new props (new but equal ISalesDesignationView from backend),
        // we copy the element ref into it
        if(!this.props.article.elementRef && this.elementRef){
            this.props.article.elementRef = this.elementRef;
        }
    }

    componentWillUnmount(): void { 
        this.setState({
            mounted: false
        });
    }

    private get isNonDesktopDevice() {
        return this.props.isMobile || isTablet || !this.state.isDesktop;
    }

    private handleTileClick(tileId: string, pageNo: number): void {
        this.props.urlController.updateUrlForPaging(pageNo, tileId);
    }

    private onMouseEnter(): void {
        clearTimeout(this.hoverStartupTimer);

        this.hoverStartupTimer = setTimeout(() => {
            this.startHover();
        }, 1000);
    }

    private startHover(): void {
        this.checkAvailableSizesViaBackend();
        this.setState({ isHovering: true });
    }

    private onMouseLeave(): void {
        clearTimeout(this.hoverStartupTimer);

        this.setState({
            selectedColorVariant: this.getDefaultColorVariant(),
            isHovering: false,
            showAvailableSizesMenu: false
        });
    }

    private getDefaultColorVariant() {
        const defaults =
            this.props.article.salesArticleVariantColors
                .filter(it => it.color.code === this.props.article.defaultColorCode);
        return defaults.length < 1 ? null : defaults[0];
    }

    private changeColorVariant(colorCode: number) {
        const colorVariants = this.props.article.salesArticleVariantColors
            .filter(it => it.color.code === colorCode);
        let selectedColor: ISdvColor;
        if (colorVariants.length > 0) {
            selectedColor = colorVariants[0];
        } else {
            selectedColor = this.getDefaultColorVariant()
        }

        this.setState({
            selectedColorVariant: selectedColor,
            pdpLink: selectedColor.pdpLink
        }, this.checkAvailableSizesViaBackend);
    }

    private checkAvailableSizesViaBackend(): void {
        if (this.props.articleTilesAjaxCaller && 
            !this.state.sizesLoaded && this.state.selectedColorVariant.isSale) {
            // get available sizes for sdv:
            this.props.articleTilesAjaxCaller.getAvailableSizes(
                this.props.category.originalRootPath,
                this.props.article.navigationKey,
                this.props.article.masterArticleNo)
            .then((data) => {
                if(data && this.state.mounted) {
                    this.setState({
                        sizes: data.sizes,
                        salesArticleVariantsLookup: data.salesArticleVariantsLookup,
                        sizesLoaded: true
                    }, this.enableAvailableSizes);
                }
            });
        }
        else {
            this.enableAvailableSizes();
        }
    }

    private toggleAvailableSizes(e?): void {
        this.preventDefaultEvents(e);

        if(this.isNonDesktopDevice) {
            const dcvColorCode = this.getDefaultColorVariant() ? 
                this.getDefaultColorVariant().color.code : null;

            this.props.showAvailableSizesOverlay(this.props.article.salesArticleVariantColors,  
                dcvColorCode, this.state.sizes, 
                this.state.salesArticleVariantsLookup,
                this.props.article.masterArticleNo);
        }
        else
            this.setState({ showAvailableSizesMenu: !this.state.showAvailableSizesMenu });
    }

    private preventDefaultEvents(e?): void {
        if (e) {
            e.preventDefault(); //the ArticleTile has a <a>-tag to link to PDP, we want to avoid navigation here
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            e.nativeEvent.stopPropagation();
        }
    }

    private get multiColorArticle(): boolean {
        return this.props.article.salesArticleVariantColors && this.props.article.salesArticleVariantColors.length > 1;
    }

    private enableAvailableSizes(): void {
        if(this.state.mounted)
            this.setState({ 
                availableSizesEnabled: this.getAvailableSizesState() 
            });
    }

    private get detailsAndVariantsSwitchAllowed(): boolean {
        return this.multiColorArticle || this.state.availableSizesEnabled;
    }

    private getAvailableSizesState(): boolean {
        // for desktop devices, avs is per article variant
        if(this.state.selectedColorVariant.isSale && this.state.sizes) {
            if(this.state.sizes.length > 1)
                return true;
            else {
                const singleSize = this.state.sizes[0];
                return singleSize && singleSize.code !== 0;
            }
        }

        return false;
    }

    private openHoverContentByTouch(): void {
        if (this.props.isMobile || !this.state.isDesktop)
            return;

        if (this.state.isHovering && !this.state.itWasTouchMove)
            window.location.href = this.state.pdpLink;
        else if (!this.state.itWasTouchMove)
            this.startHover();

        // if it was not movement, on next click pdp will be opened
        this.setState({ itWasTouchMove: false });
    }

    private getPdpLink(): string {
        const pdpLink = this.props.addItemOriginToPdpLink ? this.state.pdpLink + '?itemorigin=SEARCH' : this.state.pdpLink;
        if(this.props.isMobile || !this.state.isTouchAction)
            return pdpLink;
    }

    private resetTouchEventState(event, stm): void {
        if (this.state.isTouchAction)
            event.preventDefault();

        this.setState({ isTouchAction: false });
        this.handleTelemetry(stm, this.props.portal, this.props.culture);
    }

    private handleTelemetry(stm: string, portal: string, culture: string): void {
        if (!stm) return;
        const data = new SearchTelemetryData(
            stm,
            PageViewLogDataId.getInstance().get(), 
            portal, 
            culture);
        SearchTelemetryAjaxCaller.postData(data);
    }

    public render() {
        const numberOfColorsForAvailableSizes = this.state.availableSizesEnabled
            ? this.props.article.salesArticleVariantColors.length
            : 0;
        const hideDetailsForHover = this.detailsAndVariantsSwitchAllowed && this.state.isHovering;
        const stm = this.props.article.telemetryData ? this.props.article.telemetryData.stm : null;

        return (
            <div id={this.props.article.tileId}
                ref={this.props.article.elementRef}
                className={styles.article_tile} 
                data-testid='article_tile'
                onClick={() => {this.handleTileClick(this.props.article.tileId, this.props.article.pageNo)}}
                onMouseEnter={this.props.isMobile || !this.state.isDesktop ? () => { return false; } : this.onMouseEnter}
                onMouseLeave={this.props.isMobile || !this.state.isDesktop ? () => { return false; } : this.onMouseLeave}
                data-stm={stm}
            >
                <a className='hidden return-anchor' href={'#' + this.props.article.tileId}></a>
                <ButtonColumn variant={this.state.selectedColorVariant}
                    seoSlug={this.props.article.seoSlug} navigationKey={this.props.article.navigationKey}
                    on_hover={this.state.isHovering} id={this.props.article.defaultSalesArticleNo} 
                    viewType={this.props.viewType} gridNumber={this.props.gridNumber}
                    isMobile={this.props.isMobile} IsGlobal={this.props.isGlobal}
                    mpcMobileTouchPointsVisible={this.props.mpcMobileTouchPointsVisible}/>
                <a
                    href={this.getPdpLink()}
                    className={classNames(styles.idv_container, {
                        [styles.filter_drop_down_open]: this.props.isFilterDropDownOpen
                    })}
                    data-testid='idv_container'
                    onTouchMove={() =>{
                        this.setState({itWasTouchMove: true});
                        }}
                    onTouchEnd={() => this.openHoverContentByTouch()}
                    onTouchStart={this.props.isMobile || !this.state.isDesktop ? () => { return false; } : () => {
                        this.setState({ isTouchAction: true });
                    }}
                    onClick={(event) => this.resetTouchEventState(event, stm)}>
                    <div className={styles.media_container}>
                        <SlideShow
                            sdvColor={this.state.selectedColorVariant}
                            on_hover={this.state.isHovering}
                            detailsPageLink={this.props.article.pdpLinkDefaultColor}
                            tileId={this.props.article.defaultSalesArticleNo}
                            showAvailableSizesMenu={this.state.showAvailableSizesMenu}
                            isMobile={this.props.isMobile}
                            articleName={this.props.article.description}
                            preventDefaultEvents={this.preventDefaultEvents}
                        />
                        <div className={styles.tile_extras}>
                            <EyeCatcher
                                isNew={this.state.selectedColorVariant.isNew}
                                hasUvp={this.state.selectedColorVariant.hasRecommendedRetailPrice}
                                sale={this.state.selectedColorVariant.isSale}
                                priceDeduction={this.state.selectedColorVariant.priceDeduction ? 
                                    this.state.selectedColorVariant.priceDeduction : this.props.article.priceDeduction}
                                isNew_l10n={helper.decodeHTML(this.props.l10n.isNew)}
                                sale_l10n={helper.decodeHTML(this.props.l10n.sale)}
                                uvp_l10n={helper.decodeHTML(this.props.l10n.uvp)}
                                setPrice={this.props.article.salesArticleType === 2}  // ArticleSet type
                                setPrice_l10n={helper.decodeHTML(this.props.l10n.setPrice)}
                                availableSizesOpen={this.state.showAvailableSizesMenu}
                                numberOfColorsForAvailableSizes={numberOfColorsForAvailableSizes}
                                flagAdvertisedAsCostFreeInSet={this.props.article.flagAdvertisedAsCostFreeInSet}
                            />
                            {
                                (this.isNonDesktopDevice && this.state.availableSizesEnabled) &&
                                <AvailableSizesBtn toggleAvailableSizes={this.toggleAvailableSizes}
                                    btnText={this.props.l10n.availableSizes} />
                            }
                        </div>
                    </div>
                    <div className={styles.dv_container}>
                        <TileDetails
                            currencyInformation={this.props.currencyInformation}
                            priceType={this.props.article.priceType}
                            minimalPrice={this.props.article.minimalPrice}
                            description={this.props.article.description}
                            variantCountInfo={this.props.article.variantCountInfo}
                            l10n={this.props.l10n}
                            hideSelfForHover={hideDetailsForHover}
                            originalPrice={this.props.article.originalPrice}
                            comparisonPrice={this.props.article.comparisonPrice}
                            isMobile={this.props.isMobile}
                            flagAdvertisedAsCostFreeInSet={this.props.article.flagAdvertisedAsCostFreeInSet}
                        />
                        {(this.detailsAndVariantsSwitchAllowed) && !this.props.isMobile &&
                            <TileVariants
                                article={this.props.article} 
                                l10n={this.props.l10n}
                                tileIsHovering={this.state.isHovering}
                                showAvailableSizesButton={this.state.availableSizesEnabled}
                                changeColorVariant={this.changeColorVariant}
                                selectedColor={this.state.selectedColorVariant.color.code}
                                toggleAvailableSizes={this.toggleAvailableSizes}
                            />
                        }
                    </div>
                </a>
                {(this.state.availableSizesEnabled && !this.isNonDesktopDevice) &&
                    <AvailableSizes l10n={this.props.l10n}
                        salesArticleVariantColors={this.props.article.salesArticleVariantColors}
                        dcvColorCode={this.getDefaultColorVariant() ? this.getDefaultColorVariant().color.code : null}
                        toggleAvailableSizes={this.toggleAvailableSizes}
                        showAvailableSizesMenu={this.state.showAvailableSizesMenu}
                        sizes={this.state.sizes}
                        salesArticleVariantsLookup={this.state.salesArticleVariantsLookup}
                    />}
            </div>
        );
    }
}
