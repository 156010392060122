import * as React from 'react';
import * as helper from '../../Common/html-helper';
import classNames from 'classnames';
import styles from './availabilityFilter.scss';
import InfoIcon from '../../Assets/svg/info';
import { IAvailabilityFilter, IAvailabilityFilterState } from './availabilityFilter.d';

export default class AvailabilityFilter extends React.Component<IAvailabilityFilter, IAvailabilityFilterState> {
    constructor(props: IAvailabilityFilter) {
        super(props);
        this.state = {};

        this.handleFiltersIsAvailableChanged = this.handleFiltersIsAvailableChanged.bind(this);
        this.onTooltipClicked = this.onTooltipClicked.bind(this);
    }

    public render() {
        return (
            <div className={styles.available_filter_container}>
                <div className={styles.af_switch}
                    data-testid='af_switch'
                    onClick={this.handleFiltersIsAvailableChanged}>
                    <input type='checkbox' checked={this.props.isAvailable}
                        onChange={this.handleFiltersIsAvailableChanged} />
                    <span className={classNames(styles.slider, styles.round)}></span>
                </div>
                <div className={styles.af_text}>{helper.decodeHTML(this.props.mltTitle)}</div>
                <div className={styles.af_text_small}>{helper.decodeHTML(this.props.mltTitleSmall)}</div>
                <div className={styles.af_info_icon}
                    data-testid='af_info_icon'
                    onClick={this.onTooltipClicked} >
                    <InfoIcon />
                </div>
            </div>
        );
    }

    private handleFiltersIsAvailableChanged(): void {
        if (this.props.setIsAvailableFilter)
            this.props.setIsAvailableFilter();
    }

    private onTooltipClicked(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.props.updateTooltip(true, this.props.tooltipText);
    }
}
