import React from 'react';
import styles from './tileDetails.scss';
import { Component } from 'react';
import { IL10N } from '../../../../Common/l10n-keys';
import * as helper from '../../../../Common/html-helper';
import {
    IScalePriceData, ICurrencyInformation, IPriceData,
    IComparisonPrice, IVariantCountInfo
} from '../article-tile.d';
import IconColors from '../../../../Assets/svg/icon_colors';
import classNames from 'classnames';

export default class TileDetails extends Component<{
    priceType: number, minimalPrice: IScalePriceData,
    description: string, variantCountInfo: IVariantCountInfo, l10n: IL10N, currencyInformation: ICurrencyInformation,
    hideSelfForHover: boolean, originalPrice: IPriceData, comparisonPrice: IComparisonPrice, isMobile: boolean,
    flagAdvertisedAsCostFreeInSet: boolean}> {
    private isGlobal: boolean;
    private originalPriceElementRef = React.createRef<HTMLDivElement>();
    private reducedPriceElementRef = React.createRef<HTMLDivElement>();
    private withVat: boolean;

    constructor(props) {
        super(props);

        this.withVat = this.getWithVatValue(this.props.currencyInformation.priceMode,
            typeof localStorage !== 'undefined' ? localStorage.getItem('PriceMode') : '');

        this.isGlobal = typeof document !== 'undefined' && document.getElementsByClassName('fas_global').length > 0;
    }

    private getWithVatValue(priceModeFromServerSide: string, priceModeCookie?: string): boolean {
        let result;
    
        switch (priceModeFromServerSide) {
            case 'Gross':
                result = true;
                break;
            case 'Net':
                result = false;
                break;
            default:
                result =
                    typeof document !== 'undefined'
                        ? document.getElementsByClassName('current-pricemode-gross').length > 0
                        : priceModeCookie == 'Gross';
        }
    
        return result;
    }

    private getPriceString(price: IPriceData): string {
        let result = '---';

        if (price) {
            result = this.getPriceStringInner(price.netValue, price.grossValue);
        }

        return result;
    }

    private getPriceStringComparison(price: IComparisonPrice): string {
        return this.getPriceStringInner(price.netValue, price.grossValue);
    }

    private getPriceStringInner(priceNet: string, priceGross: string): string {
        let numberStr: string;
        if (this.withVat && !this.isGlobal) {
            numberStr = priceGross;
        } else {
            numberStr = priceNet;
        }
        const result = this.props.currencyInformation.prependCurrencySymbol 
            ? `${this.props.currencyInformation.symbol} ${numberStr}`
            : `${numberStr} ${this.props.currencyInformation.symbol}`;

        return result;
    }

    private get minQuantity(): number {
        return this.props.minimalPrice ? this.props.minimalPrice.quantity : 0;
    }

    public render() {
        const l10n = this.props.l10n;
        const vatAcronym = this.withVat ? l10n.incVATAcronym : l10n.exVATAcronym;
        const vatString = !this.isGlobal ? `(${vatAcronym}) ` : '';
        const extraStringInternal = (this.minQuantity > 1) ?
         `${vatString}${l10n.priceFromLocale} ${this.minQuantity} ${l10n.items}` : `${vatString}`;
        const extraString =
            helper.decodeHTML(extraStringInternal);
        const showOriginalPrice = this.props.originalPrice && !this.props.flagAdvertisedAsCostFreeInSet;
        const showBreakLineOriginalPrice = this.originalPriceElementRef.current ?
            this.originalPriceElementRef.current.clientHeight > 20 : false;

        const showBreakLineReducedPrice = this.reducedPriceElementRef.current ?
            this.reducedPriceElementRef.current.clientHeight > 20 : false;
            

        return (
            <div className={classNames(styles.t_details,
                { [styles.hidden]: this.props.hideSelfForHover })}>
                <div className={styles.d_title}>
                    {this.props.description}
                </div>
                <div className={styles.d_bottom}>
                    <>
                        {this.props.isMobile ?
                            <>
                                <div className={styles.d_p_extra}>
                                    {this.renderVariantCountText()}
                                </div>
                            </>
                            : null
                        }
                    </>
                    <div className={styles.d_price_container}>
                        {showOriginalPrice &&
                            <span className={styles.d_original_price} ref={this.originalPriceElementRef}>
                                {this.props.priceType > 1 &&
                                    <span>
                                        {helper.decodeHTML(l10n.priceFromLocale)}&nbsp;
                                    </span>
                                }
                                {showBreakLineOriginalPrice && <br/>}
                                <span>{this.getPriceString(this.props.originalPrice)}</span>
                            </span>
                        }

                        <span className={classNames(styles.d_price,
                            { [styles.d_reduced_price]: showOriginalPrice })} ref={this.reducedPriceElementRef}>
                                {this.props.priceType > 1 &&
                                    <span>
                                        {helper.decodeHTML(l10n.priceFromLocale)}&nbsp;
                                    </span>
                                }
                                {showBreakLineReducedPrice && <br/>}
                            {!this.isGlobal ?
                                <span>{this.getPriceString(this.props.minimalPrice.price)}</span>
                                : <span data-ge-price>{this.getPriceString(this.props.minimalPrice.price)}</span>
                            }
                        </span>
                    </div>
                    {this.props.comparisonPrice ?
                        <div className={styles.d_price_comparison_basic}>
                            {helper.decodeHTML(l10n.comparisonBasicPrice)}{helper.decodeHTML(l10n.colon+' ')}
                            {this.getPriceStringComparison(this.props.comparisonPrice)}
                            /{this.props.comparisonPrice.unit}
                        </div> : null
                    }
                    <div className={styles.d_p_extra}>
                        <span className={styles.d_p_span_extra_string}>{extraString}</span>
                        {!this.props.isMobile ? this.renderVariantCountText() : null}
                    </div>
                </div>
            </div>
        );
    }

    private renderVariantCountText() {
        if (this.props.variantCountInfo && this.props.variantCountInfo.mode === VariantCountMode.Colors) {
            return <div className={styles.d_colors}>
                <span className={styles.c_icon}><IconColors /></span>
                {!this.props.variantCountInfo.labelContainsCountsAlready &&
                    <span className={styles.c_amount}>{this.props.variantCountInfo.totalCount}</span>}
                <span className={styles.c_subfix}>
                    {helper.decodeHTML(this.props.variantCountInfo.labelLocalized)}
                </span>
            </div>
        } else if (this.props.variantCountInfo && this.props.variantCountInfo.mode === VariantCountMode.Models) {
            return <div className={styles.d_models}>
                    {!this.props.variantCountInfo.labelContainsCountsAlready &&
                        <span className={styles.c_amount}>{this.props.variantCountInfo.totalCount}</span>}
                    <span className={styles.c_subfix}>
                        {helper.decodeHTML(this.props.variantCountInfo.labelLocalized)}
                </span>
            </div>
        }
    }
}

// placing it in article-tile.d.ts does not work because d.ts files are special 
// https://stackoverflow.com/questions/38335668/how-to-refer-to-typescript-enum-in-d-ts-file-when-using-amd
enum VariantCountMode {
    None = 0,
    Colors = 1,
    Models = 2
}
