import * as helper from '../../Common/html-helper';
import * as FasBarTypes from './fas-bar.d';
import { SortType } from '../sort-side-content/sort-side-content.d';
import * as React from 'react';
import styles from './fas-bar.scss';
import FilterDropDown from '../filterDropDown/filterDropDown';
import {
    AvailableFiltersState,
    IFilterModelChangedEvent,
    ICategoryData
} from '../globalState/AvailableFiltersState';
import { IFilterSelectionChangedEvent, SelectedFiltersState } from '../globalState/SelectedFiltersState';
import { ISelectedFilter } from '../globalState/selectedFilters.d';
import { Handler } from '../globalState/globalState';
import { convertFromServiceToReactWorld } from '../globalState/ServiceSelectedFilterConverter';
import { FilterAjaxUpdateController } from '../globalState/FilterAjaxUpdateController';
import { IFilterProperty } from '../fas-bar/filter-property.d';
import { IFilterPropertyValue } from '../fas-bar/filter-property-value.d';
import FilterIcn2021 from '../../Assets/svg/filter_2021';
import ProductFinderIcn2021 from '../../Assets/svg/productfinder_2021';
import KioskFilter from '../kiosk-filter/kiosk-filter';
import * as Constants from '../../Common/constants';
import classNames from 'classnames';
import SpinnerComponent from '../spinnerComponent/spinnerComponent';
import FragmentPlaceholder from '../../Common/fragment-placeholder/fragment-placeholder';
import SortIcn2021 from '../../Assets/svg/sort_2021';
import CrossIcn from '../../Assets/svg/cross_2021';
import { isTablet } from 'react-device-detect';
import { SortContext } from '../globalState/sortContextProvider';
import { ISortContext } from '../globalState/sortContextProvider.d';
import { ViewType } from '../../Common/enums';
import { publish } from '../../Common/customEventHelper';

export default class FasBar extends React.Component<FasBarTypes.IFasBarProps,
    FasBarTypes.IFasBarState> {
    static contextType = SortContext;

    private readonly availableFiltersState: AvailableFiltersState;
    private readonly selectedFilterState: SelectedFiltersState;
    private readonly componentName: string = 'fas-bar';
    private windowWidth: number;
    private readonly initialGloveSortCount: number;

    private elementRef = React.createRef<HTMLDivElement>();
    private top4FilterElementRef = React.createRef<HTMLDivElement>();
    private filterBtnElementRef = React.createRef<HTMLDivElement>();
    private sortBtnElementRef = React.createRef<HTMLDivElement>();

    constructor(props: FasBarTypes.IFasBarProps, context) {
        super(props, context);
        this.state = {
            // we need only one spinner. If dropDown or sidePanel is open, cat., overlay is not visible:
            fasBarVisible: true,
            mspOpen: false,
            filterModel: props.filterModel,
            selectedFilters: convertFromServiceToReactWorld(
                props.userSelectedFilters,
                props.filterModel),
            topDisplay: 4,
            windowWidth: 0,
            isMounted: false,
        };
        this.removeAllFilters = this.removeAllFilters.bind(this);
        this.addSelectedFilter = this.addSelectedFilter.bind(this);
        this.removeSelectedFilter = this.removeSelectedFilter.bind(this);
        this.handleFilterIsAvailableChange = this.handleFilterIsAvailableChange.bind(this);
        this.globalyRemoveSelectedFilter = this.globalyRemoveSelectedFilter.bind(this);
        this.isAvailableFilterActive = this.isAvailableFilterActive.bind(this);
        this.updateWindowWidth = this.updateWindowWidth.bind(this);
        this.getGlobalArticleCountString = this.getGlobalArticleCountString.bind(this);

        const categoryData: ICategoryData = {
            categoryPath: this.props.categoryPath,
            navigationKey: this.props.navigationKey,
            seoSlug: this.props.seoSlug,
        };

        this.availableFiltersState = new AvailableFiltersState(
            this.props.globalState, categoryData, props.culturePrefix, this.componentName,
            this.props.filterModel, this.state.selectedFilters, this.props.searchTerm, this.props.kiosk);
        this.selectedFilterState = this.availableFiltersState.selectedFilterState;
        this.props.globalState.selectedFiltersState = this.selectedFilterState;
        this.availableFiltersState.registerOnStateChanged(this.onAvailableFiltersChanged.bind(this));
        this.selectedFilterState.registerOnStateChanged(this.onSelectedFiltersChanged.bind(this));
        this.initialGloveSortCount = this.props.sorting?.gloves?.filter(gloveSort => gloveSort.sort > 0).length || 0

        this.initSort();
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', this.handleScroll.bind(this));

            if (typeof window.shell !== 'undefined') {
                window.shell.subscribeTo(
                    'ESPP.MainSidePanel.Opened',
                    () => {
                        this.setState({ mspOpen: true });
                    }, null);

                window.shell.subscribeTo(
                    'ESPP.MainSidePanel.Closed',
                    () => {
                        this.setState({ mspOpen: false });
                    }, null);
            }
        }
    }

    public handleFilterIsAvailableChange(): void {
        if (!this.state.filterModel.filters)
            return;

        const filter: IFilterProperty | undefined
            = this.state.filterModel.filters.find((x) => x.name === Constants.DELIVERY_TIME);
        const isSelected: boolean = this.isAvailableFilterActive();
        if (typeof filter !== 'undefined')
            if (isSelected) {
                const selectedFilter = {
                    dimension: filter,
                    value: filter.filterPropertyValues[0],
                } as ISelectedFilter;
                this.removeSelectedFilter(selectedFilter);
            } else
                this.addSelectedFilter(filter, filter.filterPropertyValues[0]);
        else {
            // eslint-disable-next-line no-console
            console.warn('Data inconsistent! delivery_time filter should always available base on design');
        }
    }

    public componentDidMount() {
        this.handleScroll(); // init call, for page reload;
        // update search count for search result
        if (this.props.isBreadcrumbOnly)
            publish('ESPP.FilterAndSort.UpdateGlobalStateDependencies',
                this.availableFiltersState.getFilterModel() ?
                    this.availableFiltersState.getFilterModel().matchingArticleCount : null);
        else
            this.updateWindowWidth();

        // enable filter button when component is mounted, only for mobile:
        if (this.props.isMobile)
            this.filterBtnElementRef.current.classList.remove(styles.f_btn_disabled);
        this.setState({
            isMounted: true,
        });
    }

    public componentDidUpdate() {
        if (this.props.isBreadcrumbOnly)
            publish('ESPP.FilterAndSort.UpdateGlobalStateDependencies',
                this.availableFiltersState.getFilterModel().matchingArticleCount);
    }

    public componentWillUnmount() {
        window.removeEventListener('load', this.updateWindowWidth);
    }

    public updateWindowWidth() {
        this.windowWidth = window.innerWidth;
        this.setState({
            windowWidth: this.windowWidth,
        });

        this.checkTop4ContainerWidth();
    }

    private checkTop4ContainerWidth() {
        const currentTop4Container: HTMLDivElement = this.top4FilterElementRef.current;
        if (currentTop4Container) {
            const childWidthArray: number[] = [];
            Array.from(currentTop4Container.children).forEach((value) => {
                childWidthArray.push(value.clientWidth);
            });

            let totalChildWidth = childWidthArray.reduce((a, b) => a + b, 0);
            while (totalChildWidth > currentTop4Container.clientWidth) {
                childWidthArray.pop();
                totalChildWidth = childWidthArray.reduce((a, b) => a + b, 0);
            }

            let currentTop4Display = childWidthArray.length;
            if (currentTop4Display < 2)
                currentTop4Display = 1;
            this.setState({ topDisplay: currentTop4Display });
        }
    }

    public handleActiveDropDownFilterChange = (prActiveDropDownFilter: string) => {
        this.props.dropDownFilterChange(prActiveDropDownFilter);
        this.availableFiltersState.restoreToGlobalState();
    }

    public render() {
        return (
            <div ref={this.elementRef} className={classNames(styles.fas_bar,
                helper.getPortalSelector(this.props.isGlobal),
                { [styles.mobile_layout]: this.isMobileView },
                { [styles.desktop_layout]: !this.isMobileView }
            )}>
                {this.renderTopFourFilters()}
                <SpinnerComponent
                    context='fas-bar'
                    isFixedLayout={true}
                    overlayBreadcrumb={false}
                    hideLoadingSpinner={true}
                    globalState={this.props.globalState} />
            </div>
        );
    }

    private renderTopFourFilters() {
        if (this.props.isBreadcrumbOnly
            || (!this.props.mainSidePanelIsAvailable && (this.props.isMobile || this.props.isTablet)))
            return null;
        else
            return (
                <div className={classNames(styles.top_layer,
                    { [styles.kiosk_layout]: !!this.props.kiosk },
                    { [styles.single_line_layout]: this.useSingleLineLayout },
                    { [styles.search_layout]: this.props.viewType === ViewType.Search },
                    { [styles.hidden_on_load]: !this.state.isMounted}
                )}>
                    {this.renderSearchResultText()}
                    {this.renderTop4Wrapper()}
                    {this.renderProductFinderButton()}
                    {
                        this.props.globalState.filterModel
                            ? (
                                <div className={styles.article_count}>
                                    {this.getGlobalArticleCountString()}
                                </div>
                            )
                            : <div></div>
                    }
                    {
                        this.props.mainSidePanelIsAvailable &&
                        (
                            this.props.isMobile || this.props.isTablet
                                ? (<div></div>)
                                : this.renderFilterButton(this.createTopFilterContainers().length)
                        )
                    }

                    {this.renderManualProductComparisonButton()}
                    <div className={styles.button_wrapper}>
                        {this.props.mainSidePanelIsAvailable &&
                            (
                                this.props.isMobile || this.props.isTablet ? this.renderFilterButton(0) : (<div></div>)
                            )}
                        {this.renderSortButton()}
                        {this.renderProductFinderButton()}
                    </div>
                </div>
            );
    }

    private get useSingleLineLayout(): boolean {
        if (this.elementRef.current) {
            const mpcBtn = this.elementRef.current.querySelector('.toolbar-button-wrapper');

            return !this.shouldShowProductFinder || !mpcBtn;
        }

        return true;
    }

    private get isMobileView(): boolean {
        return !this.props.kiosk && (this.props.isMobile || this.props.isTablet || isTablet);
    }

    private shouldShowProductFinder(): boolean {
        return this.props.productFinders && this.props.productFinders.length > 0;
    }

    private renderSearchResultText() {
        if (this.props.viewType === ViewType.Search) {
            const maxLength = 28;
            const shouldHaveEllipsis = this.props.originalSearchTerm.length > maxLength;
            const searchTerm = shouldHaveEllipsis
                ? this.props.originalSearchTerm.substring(0, 24) + '...'
                : this.props.originalSearchTerm;

            return (
                <div className={styles.search_result}>
                    <span className={styles.search_label}>
                        {this.props.l10n.yourSearchFor}
                    </span>
                    <span className={styles.search_string}>
                        <span dangerouslySetInnerHTML={{ __html: this.props.l10n.startQuotationMark }}></span>
                        <span data-testid='search_term'>{this.props.originalSearchTerm}</span>
                        <span dangerouslySetInnerHTML={{ __html: this.props.l10n.endQuotationMark }}></span>
                    </span>
                    {!(this.props.isMobile || this.props.isTablet) &&
                        <span className={styles.search_string_ellipsis}>
                            <span dangerouslySetInnerHTML={{ __html: this.props.l10n.startQuotationMark }}></span>
                            <span data-testid='search_term_ellipsis'>{searchTerm}</span>
                            <span dangerouslySetInnerHTML={{ __html: this.props.l10n.endQuotationMark }}></span>
                        </span>
                    }
                </div>
            );
        }
    }

    private renderTop4Wrapper() {
        const topFilters = this.createTopFilterContainers();
        const notRender = this.props.isMobile;

        if (notRender)
            return (<div></div>);
        else if (this.props.kiosk) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (<KioskFilter {...this.props}
                availableFilterState={this.availableFiltersState} />);
        }
        else
            return (
                <div className={classNames(styles.top_4_wrapper, {
                    [styles.invisible]: this.state.windowWidth <= 0,
                })}>
                    {
                        topFilters.length > 0 ?
                            <div className={styles.top_filter} ref={this.top4FilterElementRef}>
                                {this.renderTopFilters()}
                            </div>
                            : <div></div>
                    }
                    {
                        this.getFilterCount() > 0 ?
                            <div className={styles.remove_all_filters_container}>
                                <hr className={styles.horizontal_line} />
                                <div className={styles.remove_all_filters_button}
                                    data-testid='remove_all_filters_button'
                                    onClick={this.removeAllFilters}>
                                    {this.props.l10n.clearAllFilters}
                                    <CrossIcn />
                                </div>
                            </div>
                            :
                            null
                    }
                </div>);
    }

    private renderFilterButton(topFilterCounts: number) {
        return (
            <div className={styles.more_filter_btn_container}>
                <div ref={this.filterBtnElementRef}
                    data-testid='more_filter_btn'
                    className={classNames(styles.more_filter_btn, {
                        [styles.f_btn_disabled]: this.props.isMobile,
                        [styles.filter_active]: this.isFilterActive(),
                    })}
                    onClick={() => {
                        this.sendEvent('Filter');
                    }}>
                    {
                        this.isFilterActive() && <div className={styles.filter_count_container}>
                            <div className={styles.filter_count}>{this.getFilterCount()}</div>
                        </div>
                    }

                    <FilterIcn2021 />
                    {
                        // -1 is delivery time, we do not count it as a filter:
                        this.props.filterModel && topFilterCounts > 0 &&
                            this.props.filterModel.filters &&
                            this.props.filterModel.filters.length - 1 > topFilterCounts
                            ? (
                                <div className={styles.more_filter_icon}>
                                    <span className={styles.filter_text}>
                                        {helper.decodeHTML(this.props.l10n.moreFilter)}
                                    </span>
                                    <span className={styles.article_count_only}>{this.getGlobalArticleCount()}</span>
                                    {this.renderArticleCountForMobile()}
                                </div>

                            )
                            : (
                                <div className={styles.more_filter_icon}>
                                    <span className={styles.filter_text}>
                                        {helper.decodeHTML(this.props.l10n.filter)}
                                    </span>
                                    <span className={styles.article_count_only}>{this.getGlobalArticleCount()}</span>
                                    {this.renderArticleCountForMobile()}
                                </div>
                            )
                    }
                </div>

            </div>);
    }

    private renderArticleCountForMobile() {
        return this.props.globalState.filterModel
            ? (
                <span className={styles.article_count_mobile}>
                    <span className={styles.ac_mlt}>{this.props.l10n.filter}</span>
                    <span className={styles.ac_brackets}>{' ('}</span>
                    {this.getGlobalArticleCount()}
                    <span className={styles.ac_brackets}>{')'}</span>
                </span>
            )
            : <span></span>
    }

    private renderSortButton() {
        const sortContext: ISortContext = this.context;
        const selectedSort = sortContext.sortPropertyList.find(f => f.selected);
        let sortText = this.props.l10n.sort;

        if (this.getGloveSortCount() > 0) {
            const popularitySort = sortContext.sortPropertyList.find(f =>
                f.sortType === SortType.Popularity);
            if (popularitySort) {
                sortText = popularitySort.title;
            }
        } else if (selectedSort) {
            sortText = selectedSort.title;
        }

        return (
            <div ref={this.sortBtnElementRef}
                data-testid='sort_button'
                className={classNames(styles.sort_btn)
                } onClick={() => {
                    this.sendEvent('Sort');
                }}>
                <SortIcn2021 />
                <div className={styles.sort_text}>
                    {helper.decodeHTML(sortText)}
                </div>
            </div>);
    }

    private renderManualProductComparisonButton() {
        return (
            this.props.isMobile ?
                <FragmentPlaceholder
                    src={this.getMPCEndpoint()}
                    ignoreErrors={true}
                    timeout={2}
                /> : <div></div>
        );
    }

    private renderProductFinderButton() {
        return (
            this.shouldShowProductFinder() ?
                <div className={styles.product_finder_btn}
                    data-testid='product_finder_btn'
                    onClick={() => {
                        this.sendEvent(this.props.productFinders?.[0].key);
                    }}>
                    <div className={styles.logo}>
                        <ProductFinderIcn2021 />
                    </div>
                    <div className={styles.product_finder_label}>
                        {helper.decodeHTML(this.props.productFinders[0].label)}
                    </div>
                </div> : (<div></div>)
        );
    }

    private getGlobalArticleCountString(): string {
        let articleCount: number;
        if (typeof window !== 'undefined' &&
            window.document && window.document.createElement) {
            articleCount = this.props.globalState.filterModel.matchingArticleCount;
        } else {
            articleCount = this.props.filterModel.matchingArticleCount;
        }

        const unitString = articleCount > 1
            ? this.props.l10n.articles
            : this.props.l10n.article;
        return articleCount?.toString()
            + ' ' + unitString;
    }

    private getGlobalArticleCount(): string {
        let articleCount: number;
        if (typeof window !== 'undefined' &&
            window.document && window.document.createElement) {
            articleCount = this.props.globalState.filterModel.matchingArticleCount;
        } else {
            articleCount = this.props.filterModel.matchingArticleCount;
        }

        return articleCount?.toString();
    }

    private getMPCEndpoint(): string {
        const mpcSearchEndpoint = '/ESPP1-ManualProductComparison/TouchPoint/ForMobileToolbar';
        const mpcCategoryEndpoint = '/ESPP1-ManualProductComparison/TouchPoint/ForMobileToolbar?categoryNavKey=' +
            this.props.navigationKey;
        return this.props.viewType === ViewType.Search ? mpcSearchEndpoint : mpcCategoryEndpoint;
    }
    private isAvailableFilterActive(): boolean {
        const deliveryTimeFilterIndex: number = this.state.selectedFilters.findIndex(
            (x) => x.dimension.name === Constants.DELIVERY_TIME);
        return deliveryTimeFilterIndex >= 0;
    }

    private removeAllFilters() {
        const sortContext: ISortContext = this.context;
        sortContext.removeAllGloveSortValues();
        this.selectedFilterState.globalRemoveAllFilters();
    }

    private addSelectedFilter(dimension: IFilterProperty, value: IFilterPropertyValue) {
        // activate loading spinner:
        this.selectedFilterState.addFilter({ dimension, value } as ISelectedFilter);
    }

    private globalyRemoveSelectedFilter(filter: ISelectedFilter) {
        this.selectedFilterState.globalRemoveFilter(filter);
    }

    private removeSelectedFilter(filter: ISelectedFilter) {
        this.selectedFilterState.removeFilter(filter);
    }

    private onSelectedFiltersChanged: Handler<IFilterSelectionChangedEvent> = (event: IFilterSelectionChangedEvent) => {
        this.setState({ selectedFilters: event.selectedFilters });
    }

    private createElement(index: number, filterProperty: IFilterProperty): JSX.Element {
        const isAvailable: boolean = this.isAvailableFilterActive();
        const availableFilterToolTip: string = this.availableFilterToolTip();

        return (
            <FilterDropDown
                filterProperty={filterProperty}
                addSelectedFilter={this.addSelectedFilter}
                removeSelectedFilter={this.removeSelectedFilter}
                selectedFilters={this.state.selectedFilters}
                key={index}
                activeDropDownFilterChange={this.handleActiveDropDownFilterChange}
                activeDropDownFilterName={this.props.activeDropDownFilterName}
                isAvailable={isAvailable}
                setIsAvailableFilter={this.handleFilterIsAvailableChange}
                l10n={this.props.l10n}
                articleCount={this.state.filterModel.matchingArticleCount}
                isAvailableToolTip={availableFilterToolTip}
                isMobile={this.props.isMobile}
                globalState={this.props.globalState}
            />);
    }

    private availableFilterToolTip(): string {
        if (!this.state.filterModel.filters)
            return null;

        const filter: IFilterProperty | undefined
            = this.state.filterModel.filters.find((x) => x.name === Constants.DELIVERY_TIME);
        return (filter !== undefined && filter.filterPropertyValues[0])
            ? filter.filterPropertyValues[0].tooltip
            : null;
    }

    private createTopFilterContainers(): Array<JSX.Element> {
        const topFilterContainer: Array<JSX.Element> = [];

        if (this.props.globalState.filterModel &&
            this.props.globalState.filterModel.filters && this.props.globalState.filterModel.filters.length > 0) {
            const relevantFilters = this.props.globalState.filterModel.filters
                .filter((x) => x.name !== Constants.DELIVERY_TIME);

            if (relevantFilters) {
                const globalTopFourFilters = relevantFilters.slice(0, 4);

                for (let index = 0; index < globalTopFourFilters.length; index++) {
                    const filterProperty: IFilterProperty = globalTopFourFilters[index];
                    const newElement = this.createElement(index, filterProperty);
                    topFilterContainer.push(newElement);
                }
            }
        }

        return topFilterContainer;
    }

    private onAvailableFiltersChanged: Handler<IFilterModelChangedEvent> = (event: IFilterModelChangedEvent) => {
        this.setState({ filterModel: event.filterModel });
    }

    private renderTopFilters() {
        if (!this.state.filterModel || !this.state.filterModel.filters)
            return (
                <div>
                    no filters found
                </div>
            );

        const topFilterContainer: Array<JSX.Element> = this.createTopFilterContainers();

        return (topFilterContainer.length > 1 ?
            (
                topFilterContainer.map((filtersContainer, index) => {
                    return (<div className={classNames(styles.filters_container,
                        {
                            [styles.hidden]: index > (this.state.topDisplay - 1),
                        })}
                        key={index}>{filtersContainer}</div>);
                })
            ) : <div></div>
        );
    }

    private sendEvent = (payload: string) => {
        if(payload)
            publish('ESPP.MainSidePanel.ShouldOpen', payload);
    }

    private handleScroll() {
        const fasBarElement: HTMLElement = this.elementRef.current;
        if (fasBarElement) {
            if (fasBarElement.getBoundingClientRect().top <= 80 && this.state.fasBarVisible) {
                if (typeof window !== 'undefined' && window.shell)
                    publish('ESPP.FilterAndSort.ShowFasButton', null);

                this.setState({ fasBarVisible: false });
            } else if (fasBarElement.getBoundingClientRect().top > 80 && !this.state.fasBarVisible) {
                if (typeof window !== 'undefined' && window.shell)
                    publish('ESPP.FilterAndSort.HideFasButton', null);

                this.setState({ fasBarVisible: true });
            }
            if (FilterAjaxUpdateController.IsRequestRunning) return;
            this.availableFiltersState.restoreToGlobalState();
        }
    }

    private initSort(): void {
        publish('ESPP.FilterAndSort.UpdateGlobalStateDependencies',
            this.availableFiltersState.getFilterModel().matchingArticleCount);
    }

    private isFilterActive(): boolean {
        return this.state.selectedFilters.length > 0 || this.getGloveSortCount() > 0;
    }

    private getGloveSortCount(): number {
        const sortContext: ISortContext = this.context;
        let gloveSortCount = 0;
        if (this.state.isMounted) {
            gloveSortCount = sortContext.activeGlovesSortPropValues.length;
        } else {
            gloveSortCount = this.initialGloveSortCount;
        }
        return gloveSortCount;
    }

    private getFilterCount(): number {
        return this.state.selectedFilters.length + this.getGloveSortCount();
    }
}
