import React, { Component } from 'react';
import styles from './slideShow.scss';
import { ISdvColor, IFileData } from '../article-tile.d';
import PlayPauseBtn from './playPauseBtn/playPauseBtn';
import classNames from 'classnames';

export default class SlideShow extends Component<
    {
        on_hover: boolean, 
        sdvColor: ISdvColor,
        detailsPageLink: string,
        articleName: string,
        tileId: string,
        showAvailableSizesMenu: boolean,
        isMobile: boolean
        preventDefaultEvents
    }, 
    {
        paused: boolean,
        animationEnded: boolean,
        slideIndex: number,
        images: IFileData[],
        sdvColorColorCode: number,
        firstSlideAnimationDuration: number
    }> {
    private readonly slideShowRef = React.createRef<HTMLDivElement>();
    private animationTimer: ReturnType<typeof setTimeout>;
    private animationStartupTimer: ReturnType<typeof setTimeout>;
    
    // public because of tests:
    private readonly animationDuration: number = 3000;
    private readonly defaultFirstSlideAnimationDuration: number = 750;
    private startTime: number;
    private timeLeft: number;
    private previousSlideIndex: number;

    constructor(props) {
        super(props);

        this.state = {
            paused: true,
            animationEnded: false,
            slideIndex: 1,
            images: this.collectUrls(true),
            sdvColorColorCode: this.props.sdvColor.color.code,
            firstSlideAnimationDuration: this.defaultFirstSlideAnimationDuration
        };

        this.handlePlayPauseClick = this.handlePlayPauseClick.bind(this);
    }

    componentDidMount(): void {
        // firs component init, it helps to avoid transition during page load:
        if (this.slideShowRef.current)
            this.slideShowRef.current.classList.add(styles.use_animation);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.on_hover !== this.props.on_hover) {
            // collect remaining or default variant images (depends if hover started or ended):
            this.setState({
                images: this.collectUrls(false)
            });

            // Reset slide show to starting point:
            if (!this.props.on_hover) {
                this.setState({
                    sdvColorColorCode: this.props.sdvColor.color.code,
                    firstSlideAnimationDuration: this.defaultFirstSlideAnimationDuration
                });
                
                clearTimeout(this.animationStartupTimer);
                this.resetSlideShow();

                // add first slide animation on startup:
                if(this.slideShowRef.current) {
                    this.slideShowRef.current.classList.add(styles.introAnimation);
                }
            } else {
                clearTimeout(this.animationStartupTimer);

                // Start the animation:
                this.animationStartupTimer = setTimeout(() => {
                    if(this.state.images.length > 1) {
                        this.previousSlideIndex = 0;
                        this.handlePlayPauseClick();

                        // remove first slide animation, avoid flickering on variant change:
                        if(this.slideShowRef.current) {
                            this.slideShowRef.current.classList.remove(styles.introAnimation);
                        }
                    }
                }, 700);
            }
        }

        // variant changed:
        if(prevProps.sdvColor.color.code !== this.props.sdvColor.color.code && this.props.on_hover) {
            // change first slide duration to regular, because only main article needs short main image animation:
            this.setState({firstSlideAnimationDuration: this.animationDuration})
            
            clearTimeout(this.animationStartupTimer);

            // Start the animation:
            this.animationStartupTimer = setTimeout(() => {
                this.resetSlideShow();

                this.setState({
                    images: this.collectUrls(false),
                    sdvColorColorCode: this.props.sdvColor.color.code
                });

                if(this.state.images.length > 1) {
                    this.previousSlideIndex = 0;
                    this.handlePlayPauseClick();
                }
            }, 700);
        }

        // when avs was opened:
        if(this.props.showAvailableSizesMenu != prevProps.showAvailableSizesMenu 
            && (!this.state.paused && this.props.showAvailableSizesMenu)) {
            this.handlePlayPauseClick();
        }
    }

    componentWillUnmount() {
        // No longer need to keep endless loop if component is unmounted:
        // clear previous timeout:
        clearTimeout(this.animationTimer);
        clearTimeout(this.animationStartupTimer);
    }

    private collectUrls(singleUrlOnly: boolean): IFileData[] {
        const result = [this.props.sdvColor.image];
        
        // mobile device use only first/ main image,
        // if hove is not active, we do not need all article images:
        if(this.props.isMobile || singleUrlOnly)
            return result;

        if(this.props.sdvColor.mainActionImage)
            result.push(this.props.sdvColor.mainActionImage);

        if(this.props.sdvColor.additionalImages && this.props.sdvColor.additionalImages.length > 0) {
            this.props.sdvColor.additionalImages.forEach(ai => {
                result.push(ai);
            });
        }

        if(this.props.sdvColor.detailedImage)
            result.push(this.props.sdvColor.detailedImage);
        
        if(this.props.sdvColor.secondaryImage)
            result.push(this.props.sdvColor.secondaryImage);

        if(this.props.sdvColor.soleImage)
            result.push(this.props.sdvColor.soleImage);

        return result;
    }

    private handlePlayPauseClick(event?): boolean {
        this.props.preventDefaultEvents(event);

        // in case if animation ended, and user clicks play once again:
        if(this.state.animationEnded)
            this.setState({animationEnded: false});

        this.setState({
            paused: !this.state.paused
        }, ()=> {
            if (this.state.paused) {
                // clear previous timeout:
                clearTimeout(this.animationTimer);
    
                this.timeLeft = this.startTime - new Date().getTime();
                this.previousSlideIndex = this.state.slideIndex;
            } else {
                this.startTime = new Date().getTime() + this.timeLeft;
            }

            // get active slide:
            const active_slide = this.slideShowRef.current.querySelector('.fas_active');

            if(active_slide) {
                this.state.paused ? active_slide.classList.add(styles.pause_zoom_in) 
                    : active_slide.classList.remove(styles.pause_zoom_in);
            }

            this.runSlideShow(this.state.slideIndex);
        });
        return false;
    }

    private runSlideShow(index: number): void {
        if (this.state.paused)
            return;

        // set next slide index:
        this.setState({ slideIndex: index });

        // animation ends here, when the last slides passes:
        if (index === this.state.images.length) {
            this.previousSlideIndex = 0;
            clearTimeout(this.animationTimer);

            this.animationTimer = setTimeout(() => {
                this.resetSlideShow();
                this.setState({animationEnded: true});
            }, this.animationDuration);
        }
        else {
            let mixedAnimationDuration = 0;

            if(this.previousSlideIndex !== index) {
                // new slide goes on:
                // clear previous timeout:
                clearTimeout(this.animationTimer);

                this.startTime = new Date().getTime() + this.animationDuration;

                if(index === 1) { 
                    // first slide should pass faster, because it is the same main image:
                    mixedAnimationDuration = this.state.firstSlideAnimationDuration;
                }
                else {
                    mixedAnimationDuration = this.animationDuration;
                }
            }
            else {
                // continue previous slide:
                mixedAnimationDuration = this.timeLeft;
            }

            this.animationTimer = setTimeout(() => {
                this.runSlideShow(++index);
            }, mixedAnimationDuration);
        }
    }

    private resetSlideShow(): void {
        this.setState({
            paused: true,
            slideIndex: 1,
            animationEnded: false
        });
    }

    private slideStyles(renderableSlideIndex: number): string {
        // only main article first image, does not have zoom in animation and animation duration is shorter:
        const isMainArticleImage = renderableSlideIndex === 1
            && this.state.firstSlideAnimationDuration === this.defaultFirstSlideAnimationDuration;

        let baseStyles = isMainArticleImage ?
            [styles.slide, styles.first].join(' ') : [styles.slide].join(' ');

        let finishingZoomInStyles = '';

        if(this.state.images.length > 1) {
            baseStyles = [baseStyles, styles.slow_zoom_in].join(' ');
            finishingZoomInStyles = styles.finishing_zoom_in;
        }

        if (this.state.slideIndex === renderableSlideIndex && isMainArticleImage) {
            // first slide does not need finish animation effect on slide change
            return [baseStyles, styles.active].join(' ');
        }
        else if (this.state.slideIndex === renderableSlideIndex
            && !isMainArticleImage && renderableSlideIndex === 1) {
            if (this.state.animationEnded) {
                // first variant slides should stop, and animation, has to be removed:
                return [styles.slide, styles.active].join(' ');
            }
            else {
                // first variant slide plays with animation like other slides:
                if (this.state.sdvColorColorCode !== this.props.sdvColor.color.code) {
                    return [styles.slide, styles.active].join(' ');
                }
                else {
                    return [baseStyles, styles.active, finishingZoomInStyles].join(' ');
                }
            }
        }
        else if (this.state.slideIndex === renderableSlideIndex) {
            // all other slides require finish animation effect on slide change
            return [baseStyles, styles.active, finishingZoomInStyles].join(' ');
        }
        else {
            if (renderableSlideIndex > 1 && renderableSlideIndex < this.state.slideIndex) {
                // finish animation during slide switch, it will keep smooth effect:
                return [baseStyles, finishingZoomInStyles].join(' ');
            }
            else
                return baseStyles;
        }
    }

    private getImageSrcSet(imgUrl: string ): string {        
        // DPR related subfix
        const subFix = this.props.isMobile ? ' 1200w,' : ' 768w,';

        // up to 768:
        const path_768 = imgUrl.replace('ArticleTileV3', 'TileImage768') + subFix;
        
        // up to HD:
        const path_hd = imgUrl + ' 1800w'

        return path_768 + ' ' + path_hd;
    }

    private createSlideShow(): JSX.Element {
        return (
            <div className={styles.slides}
                data-testid='slides'>
                {this.state.images.map((img, imageIndex) => {
                    const tmpIndex = imageIndex + 1;
                    return <img src={img.fullPath} 
                        srcSet={this.getImageSrcSet(img.fullPath)}
                        loading='lazy'
                        key={tmpIndex}
                        decoding={'sync'}
                        data-index={tmpIndex}
                        className={classNames(this.slideStyles(tmpIndex))}
                    alt={this.props.articleName}
                        data-testid={'slide'} />
                })}
            </div>
        );
    }

    public render() {
        return (
            <div ref={this.slideShowRef} className={classNames(styles.tile_slide_show, styles.introAnimation,
                { [styles.play]: this.props.on_hover && this.state.images.length > 0 })}
                data-testid={'tile_slide_show'}>
                <div onClick={this.handlePlayPauseClick} data-testid='playPauseBtnHandler'
                    onTouchEnd={this.handlePlayPauseClick}>
                    {
                        this.state.images.length > 1 &&
                        <PlayPauseBtn imageAmount={this.state.images.length}
                            slideShowPaused={this.state.paused}
                            slideIndex={this.state.slideIndex}
                            on_hover={this.props.on_hover}
                            sdvColorColorCode={this.state.sdvColorColorCode}
                            tileId={this.props.tileId}
                        />
                    }
                </div>
                {this.createSlideShow()}
            </div>
        );
    }
}
